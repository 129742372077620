import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { LayoutComponent } from './layout/feature/layout.component';
import { ForbiddenComponent } from './login/feature/forbidden/forbidden.component';
import { LogoutRedirectComponent } from './login/feature/logout-redirect/logout-redirect.component';
import { RedirectComponent } from './login/feature/redirect/redirect.component';
import { UnauthorizedComponent } from './login/feature/unauthorized/unauthorized.component';
const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'setting' },
	{
		path: 'redirect',
		component: RedirectComponent
	},
	{
		path: 'logoutredirect',
		component: LogoutRedirectComponent
	},
	{
		path: 'unauthorized',
		component: UnauthorizedComponent
	},
	{
		path: 'forbidden',
		component: ForbiddenComponent
	},
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AutoLoginPartialRoutesGuard],
		children: [
			{
				path: 'chat',
				loadChildren: () => import('./chat/feature/chat-shell/chat.module').then((m) => m.ChatModule)
			},
			{
				path: 'setting',
				loadChildren: () => import('./setting/feature/setting.module').then((m) => m.SettingModule)
			},
			{
				path: 'zoho-crm-redirect',
				loadChildren: () => import('./redirect-zoho/feature/redirect-zoho.module').then((m) => m.RedirectZohoCRMModule)
			},
			{
				path: 'contact',
				loadChildren: () => import('./contact/feature/contact.module').then((m) => m.ContactModule)
			},
			{
				path: 'profile',
				loadChildren: () => import('./profile/feature/profile.module').then((m) => m.ProfileModule)
			},
			{
				path: 'agents',
				loadChildren: () => import('./agent/agent.module').then((m) => m.AgentModule)
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
