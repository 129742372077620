import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { emitData } from '../../data-access/interface/emitData.interface';
import { Contact } from '../../data-access/interface/search-contact.type';
import { SupportType } from '../../data-access/interface/search-supportType.interface';
import { TicketType } from '../../data-access/interface/search-ticketType.interface';
import { clientTypes } from '../../utils/codesData/clientTypes';

@Component({
	selector: 'app-ticket-form',
	templateUrl: './ticket-form.component.html',
	styleUrls: ['./ticket-form.component.scss']
})
export class TicketFormComponent {
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

	private fb = inject(FormBuilder);

	public form = this.fb.nonNullable.group({
		agent_id: ['', [Validators.required]],
		type_account: [''],
		name_account: [''],
		name_contact: [''],
		ticket_type_id: ['', [Validators.required]],
		support_type_id: ['', [Validators.required]],
		description: ['', [Validators.required]],
		contact_id: ['', [Validators.required]],
		account_partner_id: [''],
		account_client_id: [''],
		allie_client_id: ['', [Validators.required]]
	});

	public supportTicket: SupportType[];
	public ticketType: TicketType[];
	public typeAccount = clientTypes;
	public infoContac: Contact;

	assignData() {
		let name_account = this.infoContac.account?.name ? this.infoContac.account?.name : '';
		let name_contact = this.infoContac.firstname + ' ' + this.infoContac.lastname;
		let type_account = this.infoContac.account?.id_account_type ? this.infoContac.account?.id_account_type : '';
		let contact_id = this.infoContac.id;
		let account_client_id = this.infoContac.account?.id_account ? this.infoContac.account?.id_account : '';

		this.form.patchValue({
			type_account: type_account,
			name_account: name_account,
			name_contact: name_contact,
			contact_id: contact_id,
			account_client_id: account_client_id,
      agent_id: localStorage.getItem('time-to-change-agent-id')
		});

		// this.form.controls['type_account'].disable();
		this.form.controls['name_account'].disable();
		this.form.controls['name_contact'].disable();
	}

	accountContentAsigD(content: string | undefined) {
		this.form.patchValue({
			account_partner_id: content
		});
	}

	accountContentAsigA(content: string | undefined) {
		this.form.patchValue({
			allie_client_id: content
		});
	}

	createTicket() {
    this.emitAction.emit({
      action: 'create',
      values: this.form.value
    })
	}

	cancel() {
    this.emitAction.emit({
      action: 'cancel',
      values: null
    })
  }

  asignAlly(content: string | undefined) {
		this.form.patchValue({
			allie_client_id: content
		});
	}
}
