import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoDeskBackendService } from '../../../data-access/zoho-desk-backend.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { MessageService } from 'src/app/integrations/zoho/utils/messages.service';

@Component({
  selector: 'app-tickets-details',
  templateUrl: './tickets-details.component.html',
  styleUrls: ['./tickets-details.component.scss']
})
export class TicketsDetailsComponent implements OnDestroy, OnInit {

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private messageService = inject(MessageService);

  private subscriptions = new Subscription();
  public ticket?:  any;
  public currentDepartmentId?: string;
  loading = true;
  public suggestions: any[] = [];
  public filteredSuggestions: any[] = [];
  public selectStatus?: string;
  public selectOwnerId?: string;
  loadingAgent = false;
  executing = false;
  updateBackList = false;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  statusTypeOptions = [
    {
      label: 'Open',
      value: 'Open'
    },
    {
      label: 'On Hold',
      value: 'On Hold'
    },
    {
      label: 'Escalated',
      value: 'Escalated'
    },
    {
      label: 'Closed',
      value: 'Closed'
    }
  ];

  ngOnInit(): void {
    this.loading = true;
    setTimeout(() => {
      this.selectStatus = this.ticket.status ;
      this.selectOwnerId = this.ticket.assigneeId ;
      this.getTicketById(this.ticket.id, false);
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  back(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: this.updateBackList,
      module: ''
    });
  }

  getTicketById(id: string, update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoDeskBackendService
    .getTicketById(id, update)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.ticket = resp.body;
          }
        }
        this.loading = false;
        this.getConversationsTickets(this.ticket.id);
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
    });
  }

  getConversationsTickets(id: string): void {
    const createRecord$ = this._ZohoDeskBackendService
    .getConversationsTickets(id, false)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {}
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
    });
  }

  filter(event: AutoCompleteCompleteEvent) {

    if (!event.query || event.query.length == 0) {
      this.filteredSuggestions = this.suggestions;
    }
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
        let suggestion = (this.suggestions as any[])[i];
        if (suggestion.value.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(suggestion);
        }
    }

    this.filteredSuggestions = filtered;
  }

  loadData(): void {
    this.loadingAgent = true;
    const loadLookup$ = this._ZohoDeskBackendService
      .suggestionsLookup('agents', this.currentDepartmentId)
        .subscribe(
          (resp: HttpResponse<any[]>) => {
            if (resp.status == 200) {
              const data = [];
              resp.body.forEach(d => {
                data.push({
                  value: d.id,
                  label: ((d.value && d.value.length > 30) ? (d.value.slice(0,30) + '...') : d.value)
                });
              });
              this.suggestions = data || [];
              this.filteredSuggestions = this.suggestions ;
              this.loadingAgent = false;
            }
            this.subscriptions.add(loadLookup$);
          },
          (error: HttpErrorResponse) => console.error(error));
  }

  changeOwner(event: any): void {
    this.executing = true;
    const createRecord$ = this._ZohoDeskBackendService
    .changeOwner(this.ticket.id, event.value)
    .subscribe((resp: HttpResponse<void>) => {
        if (resp.status == 200) {
          this.messageService.showMessage('The agent has been successfully changed', 'success', true);
        }
        this.executing = false;
        this.loading = false;
        this.subscriptions.add(createRecord$);
        this.updateBackList = true;
    }, () => {
        this.loading = false;
        this.executing = false;
    });
  }

  changeStatus(event: any): void {
    this.executing = true;
    const createRecord$ = this._ZohoDeskBackendService
    .changeStatus(this.ticket.id, event.value)
    .subscribe((resp: HttpResponse<void>) => {
        if (resp.status == 200) {
          this.ticket.status = event.value ;
          this.messageService.showMessage('The status has been successfully changed', 'success', true);
          this.executing = false;
          this.updateBackList = true;
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
        this.executing = false;
    });
  }

  update(): void {
    this.getTicketById(this.ticket.id, true);
  }

  closeTickets(): void {
    this.executing = true;
    const createRecord$ = this._ZohoDeskBackendService
    .closeTicket(this.ticket.id)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          this.ticket.status = 'Closed';
          this.selectStatus = this.ticket.status ;
          this.messageService.showMessage('The ticket has been closed successfully', 'success', false);
          this.executing = false;
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
        this.executing = false;
    });
  }
}
