
<h6  class="mb-1 pl-3 pr-3" >
  <div class="row">
    <div class="col-1">
      <i
        *ngIf="!showBack()"
        (click)="back()"
        pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
        class="pi pi-arrow-left mr-1"
        tooltipPosition="left"
      ></i>
    </div>
    <div class="col-9">
      {{ 'Integration.Zoho.Configuration' | translate }}
    </div>
  </div>
</h6>

<div class="pl-3 pr-3">
    <div class="row">
      <div class="col-12" >
        <span>{{ 'Integration.Zoho.Select Layouts' | translate }}</span>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-6 mb-1" *ngFor="let key of getValues()">
            <h6> {{ 'Integration.Zoho.' + key | translate }}</h6>
            <div formGroupName="{{ key }}">
              <div *ngFor="let option of layoutResponse[key]">
                <p-checkbox
                  [formControlName]="option.name"
                  label="{{ option.name }}"
                  [binary]="true"
                  (click)="updateSelection(key, option.name)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p-inputSwitch
              formControlName="showOnlyRequiredFields"
              (click)="updateShowOnlyRequiredFields()"
              class="ng-dirty ng-invalid"
              inputId="chkshowOnlyRequiredFields"
            />
            <label class="ml-3"  for="chkshowOnlyRequiredFields">
              {{ 'Integration.Zoho.Only show required fields on forms' | translate }}
            </label>
          </div>
        </div>

        <div class="flex justify-content-center flex-wrap align-items-center mt-5">
          <p-button label="{{ 'Integration.Zoho.' + getButtonTitle() | translate }}" icon="pi pi-refresh" size="small" class="mr-2"
            type="submit" [disabled]="sending" />
        </div>

    </form>
  <hr />
  <div class="flex justify-content-center flex-wrap align-items-center mt-5">
    <p-button
      label="{{ 'Integration.Zoho.Log out Zoho' | translate }}"
      icon="pi pi-power-off"
      size="small"
      class="mr-2"
      severity="danger"
      (onClick)="confirm()"
    />

    <p-confirmDialog
        key="positionDialog"
        [position]="'right'"
        rejectButtonStyleClass="p-button-outlined" >
        <ng-template pTemplate="message" let-message>
          <div class="custom-dialog-content">
              {{ message.message }}
          </div>
      </ng-template>
    </p-confirmDialog>
  </div>

</div>
