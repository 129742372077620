import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chat } from '../../data-access/models/chat';

@Component({
	selector: 'app-chat-history-item',
	templateUrl: './chat-history-item.component.html',
	styleUrls: ['./chat-history-item.component.scss']
})
export class ChatHistoryItemComponent implements OnInit {
	@Input() public chat: Chat;
	@Input() public isFirstItem: boolean = false;
	@Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();
	public isSelected = false;

	private translate = inject(TranslateService);

	public ngOnInit(): void {
		if (this.isFirstItem) {
			this.isSelected = true;
			this.emitSelection();
		}
	}

	public sendConversation(event: any) {
		this.removeClass();
		this.addClass(event);
		event.preventDefault();
		this.emitSelection();
		this.isSelected = true;
	}

	public emitSelection(): void {
		this.dataChanged.emit({ conversationId: this.chat.id, phoneNumber: this.chat.clientPhone });
	}

	private removeClass(): void {
		var removeClass = document.querySelectorAll('.chat-user-list li');
		removeClass.forEach((element: any) => {
			element.classList.remove('active');
		});
		this.isSelected = false;
	}

	private addClass(event: any): void {
		event.target.closest('li').classList.add('active');
	}

	public getTime(timeStamp: Date | number): string {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

		let date: Date;

		if (typeof timeStamp === 'number') {
			date = new Date(timeStamp * 1000);
		} else {
			date = timeStamp;
		}

		const isToday =
			date.getFullYear() === today.getFullYear() &&
			date.getMonth() === today.getMonth() &&
			date.getDate() === today.getDate();

		const isYesterday =
			date.getFullYear() === yesterday.getFullYear() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getDate() === yesterday.getDate();

		let language = this.translate.currentLang + '-' + this.translate.currentLang.toUpperCase();
		if (isToday) {
			const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true } as const;
			return date.toLocaleTimeString(language, timeOptions);
		} else if (isYesterday) {
			return 'Yesterday';
		}

		const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' } as const;

		return date.toLocaleDateString(language, dateOptions);
	}
}
