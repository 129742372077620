import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from '../../../models/emitData.interface';
import { ZohoCrmBackendService } from '../../data-access/zoho-crm-backend.service';
import { RecordFormComponent } from '../record-form/record-form.component';
import { MessageService } from '../../../utils/messages.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-contact-details',
	templateUrl: './contact-details.component.html',
	styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit, OnDestroy {

  @ViewChild('childEditRecord', { read: ViewContainerRef }) container!: ViewContainerRef;
  showEditRecord = false;

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
	public translate = inject(TranslateService);

  public complete?: boolean;
  public record?: any;
  public module?: string;

  loaded = false;
  updating = false;
  activeTab = 'tab1';

  private subscriptions = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.loaded = true;
      if (this.complete) {
        this.updateRecord(false);
      }
    });
  }

  ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

  setActiveTab(tabId: string) {
    this.activeTab = tabId;
  }

  cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: this.module
		});
	}

  openOnZohoCRM(recordId: number) {
    const domainName = localStorage.getItem('ZohoCrmOrgDomainName');
    window.open('https://crm.zoho.com/crm/'+domainName+'/tab/'+this.capitalize(this.module)+'/'+recordId);
  }

  errorHandler(error: HttpErrorResponse) {
   	this.emitAction.emit({
			action: 'error',
			values: error,
      module: this.module
		});
	}

  edit(): void {
    this.emitAction.emit({
			action: 'edit',
			values: this.record,
      module: this.module
		});
  }

  capitalize(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  emitOthersAction(emit: any): void {
    this.emitAction.emit(emit);
  }

  back(): void {
    this.emitAction.emit({
      action: 'back',
      values: '',
      module: ''
    });
  }

  getInitials(name: string): string {
    if (!name) return '';
    const words = name.trim().split(' ').filter(word => word.length > 0);
    if (words.length > 1) {
      return (words[0][0] + words[words.length - 1][0]).toUpperCase();
    }
    return words[0].slice(0, 2).toUpperCase();
  }

  cutName(name: string): string {
    if (!name) return '';
    if (name.length <= 18) return name;
    return name.slice(0, 18) + '...';
  }

  updateRecord(update: boolean): void {
    this.updating = true;
    const updateRecord$ = this._ZohoCrmBackendService
    .getRecord(this.module, this.record.id, update)
    .subscribe((res: HttpResponse<any>) => {
        if (res && res.body) {
          this.record = res.body;
        }
        this.subscriptions.add(updateRecord$);
        this.updating = false;
    },
    (error: HttpErrorResponse) => {
      this.updating = false;
      this.errorHandler(error);
    });
  }

  editRecord() {
    this.showEditRecord = true;
		this.container.clear();
		const recordFormComponent = this.container.createComponent(RecordFormComponent);
		recordFormComponent.instance.record = this.record;
		recordFormComponent.instance.module = this.module;
		const recordFormComponent$ = recordFormComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordFormComponent$);
			if (values.action === 'update-record') {
				this.messageService.showMessage('The record has been updated.', 'success', true);
        this.record = values.values ;
        this.showEditRecord = false;
        this.container.clear();
			}
			if (values.action === 'error') {
				this.errorHandler(values.values);
			}
			if (values.action === 'cancel') {
				this.showEditRecord = false;
        this.container.clear();
			}
		});
	}

  backEdit(): void {
    this.showEditRecord = false;
    this.container.clear();
  }

  convert(): void {
    this.updating = true;
    const updateRecord$ = this._ZohoCrmBackendService
    .convertLead(this.record.id)
    .subscribe((res: HttpResponse<any>) => {
        if (res && res.body) {
          this.record = res.body;
          this.module = res.body._module
          // this.updateRecord(true);
          this.updating = false;
        }
        this.subscriptions.add(updateRecord$);
    },
    (error: HttpErrorResponse) => {
      this.updating = false;
      this.errorHandler(error);
    });
  }

  updateList(id: number): void {
    this.emitAction.emit({
      action: 'update-list',
      values: id,
      module: ''
    });
  }

  confirm() {
    this.confirmationService.confirm({
        message: this.translate.instant('Integration.Zoho.Are you sure you want to convert the prospect?'),
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.convert();
        },
        key: 'convertDialogConfirm'
    });
  }

}
