import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Organization } from '../../models/organization-response.model';
import { IntegrationConfigZohoCrm } from '../../models/integration-config-zoho-crm';
import { LayoutMetadata } from '../../models/layout-metadata.model';
import { IntegrationConfigResponse } from '../../models/integration-config-response.model';

@Injectable({
	providedIn: 'root'
})
export class ZohoDeskBackendService {
	private _http = inject(HttpClient);

	private baseUrl = environment.URL_API_ZOHO;
  private baseDeskUrl = this.baseUrl + '/desk';

	registerIntegration(): Observable<HttpResponse<Organization>> {
		const URL = this.baseDeskUrl + '/company';
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<Organization>(URL, { observe: 'response', headers: headers });
	}

  closeIntegration(): Observable<HttpResponse<void>> {
		const URL = this.baseDeskUrl + '/integration/close' ;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<void>(URL, { observe: 'response', headers: headers });
	}

  registerIntegrationConfig(integrationConfigZohoCrm: IntegrationConfigZohoCrm): Observable<HttpResponse<LayoutMetadata>> {
    const URL = this.baseDeskUrl + '/integration-config';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<LayoutMetadata>(
      URL,
      integrationConfigZohoCrm,
      { observe: 'response', headers: headers  }
    );
  }

  getLayoutsConfig(): Observable<HttpResponse<IntegrationConfigResponse>> {
		const URL = this.baseDeskUrl + '/integration-config';
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<IntegrationConfigResponse>(URL, { observe: 'response', headers: headers });
	}

  searchContacts(phone: string): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/contacts/search/' + phone;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  searchContactsByTerm(term: string): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/contacts/search/term/' + term;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  createContact(record: any): Observable<HttpResponse<any>> {
    const URL = this.baseDeskUrl + '/contacts' ;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<any>(
      URL,
      record,
      { observe: 'response', headers: headers  }
    );
  }

  updateContact(id: string, record: any): Observable<HttpResponse<any>> {
    const URL = this.baseDeskUrl + '/contacts/'  + id;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<any>(
      URL,
      record,
      { observe: 'response', headers: headers  }
    );
  }

  getTickets(id: string, update: boolean, page: number): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/tickets/' + id + '?update=' + update + '&page=' + page;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  getOpenTickets(update: boolean, page: number): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/tickets/open?update=' + update + '&page=' + page;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  getUnassignedTickets(update: boolean, page: number): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/tickets/unassigned?update=' + update  + '&page=' + page;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  filterTickets(status: string, channel: string, page: number): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/tickets/filter' + '?status=' + status + '&channel=' + channel  + '&page=' + page;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  getTicketById(id: string, update: boolean): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/tickets/id/' + id + '/' + update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any>(URL, { observe: 'response', headers: headers });
	}

  getByTicketNumber(ticketNumber: string, update: boolean, page: number): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/tickets/ticketNumber/' + ticketNumber + '?update=' + update + '&page=' + page;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any>(URL, { observe: 'response', headers: headers });
	}

  searchTickets(term: string, update: boolean): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/tickets/search/' + term + '?update=' + update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any>(URL, { observe: 'response', headers: headers });
	}

  searchTicketsByAgent(term: string, update: boolean, page: number): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/tickets/agent/' + term + '?update=' + update + '&page=' + page;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any>(URL, { observe: 'response', headers: headers });
	}

  getContacts(id: string): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/contacts/' + id;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any>(URL, { observe: 'response', headers: headers });
	}

  createTickets(tickets: any): Observable<HttpResponse<any>> {
    const URL = this.baseDeskUrl + '/tickets' ;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post<any>(
      URL,
      tickets,
      { observe: 'response', headers: headers  }
    );
  }

  search(term: string): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/search?term=' + term;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  searchLookup(module: string, term: string): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/search/lookup?term=' + term + '&module=' + module;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  suggestionsLookup(module: string, departmentId: string): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/suggestions/lookup?module=' + module + '&departmentId=' + departmentId;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  getHistoryTickets(ticketId: string, update: boolean): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/tickets/history/' + ticketId + '?update=' + update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  getConversationsTickets(ticketId: string, update: boolean): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/tickets/conversations/' + ticketId + '?update=' + update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  getResolutionTickets(ticketId: string, update: boolean): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/tickets/resolution/' + ticketId + '?update=' + update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  getMetricsTickets(ticketId: string, update: boolean): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/tickets/metrics/' + ticketId + '?update=' + update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any>(URL, { observe: 'response', headers: headers });
	}

  getDepartaments(): Observable<HttpResponse<any[]>> {
		const URL = this.baseDeskUrl + '/departaments';
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any[]>(URL, { observe: 'response', headers: headers });
	}

  changeOwner(ticketId: string, ownerId: string): Observable<HttpResponse<void>> {
		const URL = this.baseDeskUrl + '/tickets/change-owner/' + ticketId + "/" + ownerId;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.put<void>(URL, {}, {observe: 'response', headers: headers});
	}

  changeStatus(ticketId: string, status: string): Observable<HttpResponse<void>> {
		const URL = this.baseDeskUrl + '/tickets/change-status/' + ticketId + "/" + status;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
		return this._http.put<void>(URL, {},{ observe: 'response', headers: headers });
	}

  closeTicket(ticketId: string): Observable<HttpResponse<void>> {
		const URL = this.baseDeskUrl + '/tickets/close-ticket/' + ticketId;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.put<void>(URL, {}, {observe: 'response', headers: headers});
	}

  getCommentsByTickets(ticketId: string, update: boolean): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/tickets/comments/' + ticketId + '?update=' + update;
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.get<any>(URL, { observe: 'response', headers: headers });
	}

  saveComment(comment: any): Observable<HttpResponse<any>> {
		const URL = this.baseDeskUrl + '/tickets/comments';
    const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this._http
			.post<any>(URL, comment, { observe: 'response', headers: headers });
	}

}
