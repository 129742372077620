import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, EMPTY, catchError } from 'rxjs';
import { CompleteLogin } from '../states/auth.actions';
import { LoadCustomer } from '../states/customer.actions';
import { LoadSubscription } from '../states/subs.actions';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	private oidcSecurityService = inject(OidcSecurityService);
	private store = inject(Store);
	private router = inject(Router);

	private isLoadingInfo = new BehaviorSubject<boolean>(false);

	handleLogin(): void {
		this.oidcSecurityService
			.checkAuth()
			.pipe(
				catchError(() => {
					return EMPTY;
				})
			)
			.subscribe(({ isAuthenticated, userData }) => {
				this.store.dispatch(new CompleteLogin(isAuthenticated));
				this.store.dispatch(new LoadCustomer(userData));
				this.store.dispatch(new LoadSubscription());

				localStorage.setItem('userName', userData.given_name);
				localStorage.setItem('userId', userData.sub);
				localStorage.setItem('role', userData.role);

				this.router.navigate(['/chat']);

				this.isInfo(true);
			});
	}

	get isLoadingInfo$() {
		return this.isLoadingInfo.asObservable();
	}

	isInfo(value: boolean) {
		this.isLoadingInfo.next(value);
	}
}
