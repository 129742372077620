import { environment } from 'src/environments/environment';
import { MenuItemI } from '../data-access/menu.interface';

export const MENU: MenuItemI[] = [
	{
		label: 'Chat',
		icon: 'message',
		link: '/chat',
		isSelected: false
	},
	{
		label: 'Contact',
		icon: 'perm_contact_calendar',
		link: '/contact',
		isSelected: false
	},
	{
		label: 'Agents',
		icon: 'group',
		link: '/agents',
		isSelected: false
	},
	{
		label: 'Settings',
		icon: 'settings',
		link: '/setting',
		isSelected: false
	}
];

export function returnMenuItems(): MenuItemI[] {
	const visibleModulContact = environment.VISIBLE_CONTACTS;

	if (!visibleModulContact) {
		return MENU.filter((item) => item.label !== 'Contact');
	}

	return MENU;
}
