import { Component, Input } from '@angular/core';
import { ListContact } from '../../data-access/interfaces/list-contact.interface';

@Component({
	selector: 'app-list-contact',
	templateUrl: './list-contact.component.html',
	styleUrls: ['./list-contact.component.scss']
})
export class ListContactComponent {
	@Input() listContacts: ListContact[] = [
		{
			lether: 'A',
			contact: [
				{ id: '123', name: 'Aaron Adams' },
				{ id: '123', name: 'Abigail Anderson' },
				{ id: '123', name: 'Adam Allen' },
				{ id: '123', name: 'Adrian Armstrong' }
			]
		},
		{
			lether: 'B',
			contact: [
				{ id: '123', name: 'Barbara Baker' },
				{ id: '123', name: 'Benjamin Barnes' },
				{ id: '123', name: 'Betty Bell' },
				{ id: '123', name: 'Bradley Bennett' }
			]
		},
		{
			lether: 'C',
			contact: [
				{ id: '123', name: 'Caleb Campbell' },
				{ id: '123', name: 'Cameron Carter' },
				{ id: '123', name: 'Carol Clark' },
				{ id: '123', name: 'Catherine Collins' }
			]
		}
	];
}
