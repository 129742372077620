import { Component, EventEmitter, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { emitData } from '../../data-access/interface/emitData.interface';

@Component({
	selector: 'app-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

	private fb = inject(FormBuilder);

	/**
	 * spellty@brilla.com.co
	 * arqu
	 */
	public form = this.fb.nonNullable.group({
		usuario: ['', [Validators.required]],
		password: ['', [Validators.required]]
	});

	public srcLogo = 'assets/images/integrations/Zoho People.svg';

	handleSetValue(controlName: string, value: string): void {
		this.form.get(controlName)?.setValue(value);
	}

	login() {
		this.emitAction.emit({
			action: 'login',
			values: this.form.value
		});
	}

	cancelTTC() {
		this.emitAction.emit({
			action: 'cancel',
			values: null
		});
	}
}
