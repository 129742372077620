<div class="row mt-1">
  <div class="col-1 pl-4">
    <i pTooltip="{{ 'Integration.Zoho.Exit' | translate }}"
       tooltipPosition="left"
       style="float: left;" (click)="back()" class="pi pi-times cursor-pointer"></i>
  </div>
  <div class="col-10 flex justify-content-center">
    <img class="mr-2" src="assets/images/integrations/Zoho CRM.svg" width="18" style="margin-top: -5px;" />
    <h5>Zoho CRM </h5>
    <div class="ml-2">
      <i tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Configure and sync Layouts' | translate }}" style="cursor: pointer"
    (click)="startIntegrationConfig()" class="pi pi-cog mr-1"></i>
    </div>
  </div>
  <div class="col-1"></div>
</div>
<hr class="header" />

<form class="p-2" [formGroup]="searchForm" (ngSubmit)="search()">
  <div class="flex align-items-center gap-2">
    <input formControlName="searchInput" type="text" pInputText
           tooltipPosition="left"
           placeholder="{{ 'Integration.Zoho.Search on Zoho' | translate }}"
      pTooltip="{{ 'Integration.Zoho.Search on Zoho' | translate }}" tooltipPosition="left"
    />
    <i  pTooltip="{{ 'Integration.Zoho.Search on Zoho' | translate }}" *ngIf="!searching" style="cursor: pointer" class="pi pi-search" (click)="search()"></i>
    <i *ngIf="searching" class="pi pi-spin pi-spinner"></i>
  </div>
</form>

<div #childZohoCRMComponents></div>
    <!-- Dinamic components below -->

<p-confirmDialog
  key="exitFormlyFormConfirm"
  [position]="'right'"
  rejectButtonStyleClass="p-button-outlined" >
  <ng-template pTemplate="message" let-message>
    <div class="custom-dialog-content">
      {{ message.message }}
    </div>
  </ng-template>
</p-confirmDialog>
