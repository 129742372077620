import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConversationEventService } from 'src/app/chat/data-access/services/conversation-event.service';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { LoginFormComponent } from '../ui/login-form/login-form.component';
import { MessageService } from '../utils/messages.service';
import { ResendConversationEventService } from '../service/resend-conversation-event.service';
import { ZohoCRMComponent } from '../zoho-crm/feature/zoho-crm.component';
import { ZohoDeskComponent } from '../zoho-desk/feature/zoho-desk.component';
import { ZohoDeskBackendService } from '../zoho-desk/data-access/zoho-desk-backend.service';
import { ZohoCrmBackendService } from '../zoho-crm/data-access/zoho-crm-backend.service';

@Component({
	selector: 'app-zoho',
	templateUrl: './zoho.component.html',
	styleUrls: ['./zoho.component.scss']
})
export class ZohoComponent implements OnInit, OnDestroy {

  @ViewChild('childZohoComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

	serviceInstance: IServiceInstance;
  zohoIntegration: string;
  zohoType: string;

	@Output() closeZCRM: EventEmitter<any> = new EventEmitter<any>();

	private _ConversationEventService = inject(ConversationEventService);
  private _ResendConversationEventService = inject(ResendConversationEventService);
  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
	private messageService = inject(MessageService);

	loadingActive = false;
	serviceInstanciaId: string;

	private subscriptions = new Subscription();

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
    this.zohoType = this.zohoIntegration;
		this.serviceInstanciaId = this.serviceInstance.id;
		this.subscribeConversationEvent();
		setTimeout(() => this.initZoho());
	}

	subscribeConversationEvent() {
		this.subscriptions.add(
        this._ConversationEventService.getEvent().subscribe((data) => {
        let zohoCredentials = this.getCredencials() ;
        if (zohoCredentials) {
          this._ResendConversationEventService.emitEvent(data);
        } else {
          this.startLogin();
        }
      })
    );
	}

  initZoho() {
    let zohoCredentials = this.getCredencials() ;
		if (zohoCredentials) {
      if (this.zohoType === 'desk') { this.initDesk(); }
      if (this.zohoType === 'crm') { this.initCRM(); }
		} else {
			this.startLogin();
		}
  }

  getCredencials(): string {
    if (this.zohoType == 'crm') return localStorage.getItem('zohoCrmCredencials')
    if (this.zohoType == 'desk') return localStorage.getItem('zohoDeskCredencials')
    return null;
  }

  startLogin(): void {
		this.container.clear();
		const onLoginComponent = this.container.createComponent(LoginFormComponent);
    onLoginComponent.instance.zohoIntegration = this.zohoType
		const onLoginEvent$ = onLoginComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onLoginEvent$);
			if (values.action === 'login') {

				const zohoCredencials: any = {}
				zohoCredencials.serviceInstanceId = this.serviceInstanciaId;
				zohoCredencials.location = 'us';
				zohoCredencials.grantToken = values.values;

        this.container.clear();

        if (this.zohoType === 'desk') {
          localStorage.setItem('zohoDeskCredencials', JSON.stringify(zohoCredencials));
          this.initDesk();
        }

        if (this.zohoType === 'crm') {
          localStorage.setItem('zohoCrmCredencials', JSON.stringify(zohoCredencials));
          this.initCRM();
        }
			}
      if (values.action === 'close') {
        this.closeZCRM.emit();
      }
		});
	}

	errorHandler(error: HttpErrorResponse): void {
    let label = 'Zoho Desk' ;
    if (this.zohoType === 'crm') { label = 'Zoho Crm'; }
		if (error && error.error && error.error.message && error.error.code) {
			const errorMessage: string = error.error.message;
			const errorCode: string = error.error.code;
			if (
        errorCode.startsWith('error.zohocrm.agent.not.contain.the.integration') ||
        errorCode.startsWith('error.zohocrm.auth') ||
        errorCode === '401' ||
        errorCode === '403' ||
        errorCode.startsWith('INVALID TOKEN ERROR') ||
        errorCode.startsWith('unauthorized.access')
      ) {
				this.messageService.showMessage('You must log in/restart your account in ' + label, 'warning', true);
				if (this.zohoType === 'crm') { this.cleanCrm(); }
        if (this.zohoType === 'desk') { this.cleanDesk(); }
        this.startLogin();
			} else {
				this.messageService.showMessage(errorMessage, 'error', false);
			}
		} else {
			this.messageService.showMessage('An error has occurred, try again', 'error', false);
		}
	}

	logout(integration: string): void {
    let closeIntegration$ = null ;
    if (integration === 'crm') {
      closeIntegration$ = this._ZohoCrmBackendService.closeIntegration().subscribe(
        (resp: HttpResponse<void>) => {
          if (resp.status == 204) {
            this.messageService.showMessage('You have closed your account integration with Zoho Crm', 'success', true);
            this.cleanCrm();
            this.startLogin();
          }
          this.subscriptions.add(closeIntegration$);
        },
        (error: HttpErrorResponse) => {
          this.errorHandler(error);
        }
      );
    }

    if (integration === 'desk') {
      closeIntegration$ = this._ZohoDeskBackendService.closeIntegration().subscribe(
        (resp: HttpResponse<void>) => {
          if (resp.status == 204) {
            this.messageService.showMessage('You have closed your account integration with Zoho Desk', 'success', true);
            this.cleanDesk();
            this.startLogin();
          }
          this.subscriptions.add(closeIntegration$);
        },
        (error: HttpErrorResponse) => {
          this.errorHandler(error);
        });
    }
	}

  initCRM(): void {
    localStorage.setItem('activeZoho', 'crm');
    const zohoCrmComponentRef = this.container.createComponent(ZohoCRMComponent);
    zohoCrmComponentRef.instance.serviceInstance = this.serviceInstance;
    zohoCrmComponentRef.instance.emitAction.subscribe((value) => {
      if (value.action === 'logout') {
          this.logout('crm');
      }
      if (value.action === 'error') {
        this.errorHandler(value.values);
      }
      if (value.action === 'close') {
        this.closeZCRM.emit();
      }
    });
  }

  initDesk(): void {
    localStorage.setItem('activeZoho', 'desk');
    const zohoDeskComponent = this.container.createComponent(ZohoDeskComponent);
    zohoDeskComponent.instance.serviceInstance = this.serviceInstance;
    zohoDeskComponent.instance.emitAction.subscribe((value) => {
      if (value.action === 'logout') {
          this.logout('desk');
      }
      if (value.action === 'error') {
        this.errorHandler(value.values);
      }
      if (value.action === 'close') {
        this.closeZCRM.emit();
      }
    });
  }

  cleanCrm(): void {
    localStorage.removeItem('zohoCrmCredencials');
    localStorage.removeItem('ZohoCrmOrgDomainName');
    localStorage.removeItem('contacts-metadata-crm');
    localStorage.removeItem('deals-metadata-crm');
    localStorage.removeItem('tasks-metadata-crm');
    localStorage.removeItem('leads-metadata-crm');
    localStorage.removeItem('zohoCrmLayouts');
    localStorage.removeItem('events-metadata-crm');
    localStorage.removeItem('calls-metadata-crm');
  }

  cleanDesk(): void {
    localStorage.removeItem('zohoDeskCredencials');
    localStorage.removeItem('ZohoDeskOrgDomainName');
    localStorage.removeItem('contacts-metadata-desk');
    localStorage.removeItem('tickets-metadata-desk');
    localStorage.removeItem('zohoDeskLayouts');
    localStorage.removeItem('ZohoDeskPortalName');
  }

}
