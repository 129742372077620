import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IServiceInstance } from '../interfaces/service-instance.interface';
import { SubscriptionState } from '../states/subs.state';
import { ResourceBaseService } from './resource-base.service';

@Injectable({
	providedIn: 'root'
})
export class ServiceInstanceService extends ResourceBaseService<any> {
	private store = inject(Store);

	constructor(http: HttpClient) {
		super(http);
	}

	protected apiPath(): string {
		return '/api/ServiceInstance';
	}
	protected baseUrl(): string {
		return environment.URL_API_SUBSCRIPTION;
	}

	public getServiceInstances(serviceType: number): Observable<IServiceInstance[]> {
		let url = `${this.baseUrl()}/api/ServiceInstance/Configured`;

		let queryParams = new HttpParams().set('ServiceType', serviceType).set('Status', 0);

		const subscriptionid = this.store.selectSnapshot(SubscriptionState.getSubscription).id;
		if (subscriptionid && subscriptionid.length > 0) {
			queryParams = queryParams.set('subscriptionid', subscriptionid);
		}

		return this.http
			.get<any>(url, {
				params: queryParams
			})
			.pipe(
				map((response) => {
					return response.results;
				})
			);
	}
}
