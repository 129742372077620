<form class="login" [formGroup]="form">
	<div class="flex flex-row justify-content-center gap-2">
		<img [src]="srcLogo" width="24" />
		<span class="title">Time to Change</span>
	</div>

	<div class="flex flex-column gap-2">
		<div class="flex flex-column gap-1">
			<span class="label">{{'timeToChange.Email' | translate}} *</span>
			<app-text-field
				[icon]="'email'"
				[placeholder]="'timeToChange.Enter your email' | translate"
				(emitValue)="handleSetValue('usuario', $event)"
			></app-text-field>
		</div>

		<div class="flex flex-column gap-1">
			<span class="label">{{'timeToChange.Password' | translate}} *</span>
			<app-text-field
				[icon]="'lock'"
				[placeholder]="'timeToChange.Enter your password' | translate"
				(emitValue)="handleSetValue('password', $event)"
			></app-text-field>
		</div>
	</div>

  <div class="flex flex-column gap-2 pl-4">
	<p-button class="w-full" [label]="'timeToChange.Login' | translate" (click)="login()" />
</div>
  <span class="forgot-your-password text-center">{{'timeToChange.Forgot your password?' | translate}}</span>
</form>
