import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { Ticket } from './../../../../../time-to-change/data-access/interface/search-ticket.interface';
import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { RecordNotesComponent } from '../notes/record-notes.component';
import { ZohoCrmBackendService } from '../../../data-access/zoho-crm-backend.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/integrations/zoho/utils/messages.service';

@Component({
  selector: 'app-record-information',
  templateUrl: './record-information.component.html',
  styleUrls: ['./record-information.component.scss']
})
export class RecordInformationComponent implements OnInit, OnDestroy {

  @ViewChild('recordNotes') recordNotesComponent!: RecordNotesComponent;
  activeIndex = 0;
  @Input("record") record?: any ;
  @Input("module") module?: string;
  loaded = false;
  headerDetailsTitle = 'Integration.Zoho.Lead Details' ;
  defaultDetails = [];
  moreDetails = [];
  seeMore = false;
  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private messageService = inject(MessageService);
  private subscriptions = new Subscription();
  public suggestions: any[] = [];
  public filteredSuggestions: any[] = [];
  public selectOwnerId?: string;
  loadingOwners = false;
  executing = false;

  ngOnInit(): void {
    this.loadData();
    setTimeout(() => {
      this.setHeaderdetailsTitle();
      this.filterKeys();
      this.loaded = true;
      this.selectOwnerId = (this.record && this.record.Owner) ? this.record.Owner.id : '' ;
    });
  }

  ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

  filterKeys() {
    Object.entries(this.record).forEach(([llave, valor]) => {
      if (
          llave === 'id' ||
          llave === 'Full_Name' ||
          llave === 'Mobile' ||
          llave === 'Email' ||
          llave === 'createdTimeAsString'  ||
          llave === 'modifiedTimeAsString' ||
          llave === 'lastActivityTimeAsString'
        ) {
        this.defaultDetails.push({
          title: llave,
          value: valor
        });
      } else {
        if (
          !llave.startsWith('$') &&
          !llave.includes("Time") &&
          !llave.includes("Date") &&
          !llave.includes("AsString") &&
          llave !== "Owner" &&
          llave !== "Layout" &&
          !llave.endsWith('_By') &&
          valor != null
        ) {
          this.moreDetails.push({
            title: this.getDisplayLabel(llave),
            value: valor
          });
        } else {
          // if (llave === "Owner") {
          //  const objectWithName = valor as { name: string };
          //  this.moreDetails.push({
          //    title: 'Owner',
          //    value:  `${objectWithName?.name || ''}`
          //  });
          // }
        }
      }
    });
  }

  mapDefaultTitles(title: string): string {
    if (title === 'id') return "Record id" ;
    if (title === 'Full_Name') return "Full name" ;
    if (title === 'modifiedTimeAsString') return "Modification time" ;
    if (title === 'createdTimeAsString') return "Creation time" ;
    if (title === 'lastActivityTimeAsString') return "Time of last activity" ;
    return title;
  }

  mapMoreTitles(title: string): string {
    if (title === 'id') return "Record id" ;
    if (title === 'Full_Name') return "Full name" ;
    if (title === 'modifiedTimeAsString') return "Modification time" ;
    if (title === 'createdTimeAsString') return "Creation time" ;
    if (title === 'lastActivityTimeAsString') return "Time of last activity" ;
    return title;
  }

  setHeaderdetailsTitle(): void {
    if(this.module === 'CONTACTS') {
      this.headerDetailsTitle = 'Integration.Zoho.Contact Details' ;
    }
  }

  getDisplayLabel(apiFieldName: string): string {
    let layoutId = this.record.Layout.id ;
    let module = this.record._module.toLowerCase() ;
    let displayLabel = '';
    const metadataAsString = localStorage.getItem(module+'-metadata-crm');
    if (metadataAsString) {
      const metadataLayouts = JSON.parse(metadataAsString);
      metadataLayouts.forEach(metadataLayout => {
        if (metadataLayout.layoutId == layoutId) {
          let sections = metadataLayout.sections;
          sections.forEach(section => {
            section.fields.forEach(_field => {
              if (_field.apiName === apiFieldName) {
                displayLabel = _field.displayLabel;
              }
            });
          });
        }
      });
    }
    return displayLabel || apiFieldName.replaceAll('_', ' ') ;
  }

  emitOthersAction(emit: any): void {
    this.emitAction.emit(emit);
  }

  onAddNote() {
    if (this.recordNotesComponent) {
      this.recordNotesComponent.showForm();
    }
  }

  onRefreshNotes() {
    if (this.recordNotesComponent) {
      this.recordNotesComponent.getNotes(true);
    }
  }

  loadData(): void {
    this.loadingOwners = true;
    const loadLookup$ = this._ZohoCrmBackendService
      .suggestionsLookup('Owner')
        .subscribe(
          (resp: HttpResponse<any[]>) => {
            if (resp.status == 200) {
              const data = [];
              resp.body.forEach(d => {
                data.push({
                  value: d.id,
                  label: ((d.value && d.value.length > 15) ? (d.value.slice(0,15) + '...') : d.value)
                });
              });
              this.suggestions = data || [];
              this.filteredSuggestions = this.suggestions ;
              this.loadingOwners = false;
            }
            this.subscriptions.add(loadLookup$);
          },
          (error: HttpErrorResponse) => console.error(error));
  }

  changeOwner(event: any): void {
    this.executing = true;
    const createRecord$ = this._ZohoCrmBackendService
    .changeOwner(this.module, this.record.id, event.value)
    .subscribe((resp: HttpResponse<void>) => {
        if (resp.status == 200) {
          this.messageService.showMessage('The owner has been successfully changed', 'success', true);
        }
        this.executing = false;
        this.subscriptions.add(createRecord$);
    }, (error: any) => {
        this.executing = false;
        this.errorHandler(error);
    });
  }

  errorHandler(error: HttpErrorResponse) {
    this.emitAction.emit({
     action: 'error',
     values: error,
     module: this.module
   });
 }
}
