import { Channel } from '../enum/channel-type.enum';
import { ConversationState } from '../enum/conversation-type.enum';
import { Message } from '../interface/chat.interface';

export class Chat {
	id: string;
	customerContact: string;
	avatar: '../icons/logoEpika-128X128.png';
	about: string;
	role: 'Frontend Developer';
	State: ConversationState;
	clientPhone: string;
	businessPhone: string;
	setupId: string;
	messages: Message[] = [];
	unSeenMsg: number;
	channel: Channel;
	groupedMessagesByDate: Message[];
	createdOnTimeStamp: Date;

	constructor(
		id: string,
		customerContact: string,
		about: string,
		clientPhone: string,
		businessPhone: string,
		message: Message | undefined,
		unSeenMsg: number,
		channel: Channel,
		state: ConversationState = ConversationState.Active,
	) {
		this.id = id;
		this.customerContact = customerContact;
		this.about = about;
		this.clientPhone = clientPhone;
		this.businessPhone = businessPhone;
		if (message != undefined) {
			this.messages.push(message);
		}

		this.unSeenMsg = unSeenMsg;
		this.channel = channel;
		this.State = state;
	}

	addMessage(message: Message) {
		this.messages.push(message);
	}
	setMessages(message: Message[]) {
		this.messages = message;
	}
	addFirstMessage(message: Message) {
		this.messages.unshift(message);
	}

	getGroupedMessagesByDate(): any {
		return this.groupedMessagesByDate;
	}

	increaseUnSeenMsg(): void {
		this.unSeenMsg++;
	}

	setGroupedMessagesByDate(): void {
		this.messages = [...this.messages].sort((a, b) => a.timeStamp - b.timeStamp);
		let currentDate: string = '';

		this.groupedMessagesByDate = this.messages.reduce((acc, message) => {
			const dateMessage = this.getFormattedDate(message.timeStamp);

			if (dateMessage !== currentDate) {
				acc.push({
					haveDate: true,
					date: dateMessage
				});
				currentDate = dateMessage;
			}

			acc.push(message);
			return acc;
		}, []);
	}

	getFormattedDate(timeStamp: number): string {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

		const date = new Date(timeStamp);

		const isToday =
			date.getFullYear() === today.getFullYear() &&
			date.getMonth() === today.getMonth() &&
			date.getDate() === today.getDate();

		const isYesterday =
			date.getFullYear() === yesterday.getFullYear() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getDate() === yesterday.getDate();

		if (isToday) return 'Today';
		else if (isYesterday) return 'Yesterday';

		const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' } as const;
		return date.toLocaleDateString('en-US', dateOptions);
	}
}
