import { Component, EventEmitter, inject, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { emitData } from '../../../models/emitData.interface';
import { ZohoCrmBackendService } from '../../data-access/zoho-crm-backend.service';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../utils/messages.service';
import { ContactDetailsComponent } from '../contact-details/contact-details.component';

@Component({
	selector: 'app-records-list',
	templateUrl: './records-list.component.html',
	styleUrls: ['./records-list.component.scss']
})
export class RecordsListComponent implements OnInit {

  @ViewChild('childDetailsRecord', { read: ViewContainerRef }) container!: ViewContainerRef;

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();
  private subscriptions = new Subscription();

  private messageService = inject(MessageService);
  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);

  public action?: string;
  public title?: string;
  public module?: string;
  public term?: string;
  public recordDetails?: any;
  page = 1;
  records = [];
  loading = false;
  showDetails = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.getRecords(false);
    });
  }

  getRecords(update: boolean): void {

    if (this.action === 'OPEN-LEADS') {
      this.getLeads(update);
    }

    if (this.action === 'OPEN-CONTACTS') {
      this.getContacts(update);
    }

    if (this.action === 'SEARCH-BY-TERM') {
      this.searchByTerm(update);
    }

    if (this.action === 'SEARCH-BY-PHONE') {
      this.searchByTerm(update);
    }

    if (this.action === 'OPEN-DETAILS') {
      this.getLocalRecords();
    }
  }

  getLeads(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoCrmBackendService
    .getLeads(update, this.page)
    .subscribe((resp: HttpResponse<any[]>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.records = [...this.records, ...resp.body];
            if (this.records.length === 1) {
              this.openDetails(this.records[0],this.records[0]._module);
            }
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  getContacts(update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoCrmBackendService
    .getContacts(update, this.page)
    .subscribe((resp: HttpResponse<any[]>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.records = [...this.records, ...resp.body];
            if (this.records.length === 1) {
              this.openDetails(this.records[0],this.records[0]._module);
            }
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
  }

  searchByPhone(phone: string, update: boolean): void {
    this.loading = true;
		const searchRecords$ = this._ZohoCrmBackendService.searchByPhone(phone, update).subscribe(
			(resp: HttpResponse<any[]>) => {
				if (resp.status == 200) {
          this.records = resp.body;
          if (this.records.length === 1) {
            this.openDetails(this.records[0],this.records[0]._module);
          }
				}
        this.loading = false;
				this.subscriptions.add(searchRecords$);
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
	}

  searchByTerm(update: boolean): void {
    this.loading = true;
		const searchRecords$ = this._ZohoCrmBackendService.search(this.term, update).subscribe(
			(resp: HttpResponse<any[]>) => {
				if (resp.status == 200) {
          this.records = resp.body;
          if (this.records.length === 1) {
            this.openDetails(this.records[0],this.records[0]._module);
          }
				}
				this.subscriptions.add(searchRecords$);
        this.loading = false;
			},  (error: any) => {
        this.loading = false;
        this.emitError(error);
      });
	}

  createLead(): void {
    this.emitAction.emit({
			action: 'create-lead',
			values: null,
      module: 'LEADS'
		});
  }

  createContact(): void {
    this.emitAction.emit({
			action: 'create-contact',
			values: null,
      module: 'CONTACTS'
		});
  }

  back(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: null,
      module: ''
    });
	}

  emitError(error: any): void {
    this.emitAction.emit({
      action: 'error',
      values: error,
      module: ''
    });
	}

  getModule(module: string): string {
    if (this.action === 'SEARCH-BY-TERM' || this.action === 'SEARCH-BY-PHONE'  || this.action === 'OPEN-DETAILS' ) {
      return module;
    }
    return this.module;
  }

  capitalize(value: string): string {
    if (!value) return value;
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  uriOnDesk(id: string, module: string): string {
    const domainName = localStorage.getItem('ZohoCrmOrgDomainName');
    return 'https://crm.zoho.com/crm/'+domainName+'/tab/'+this.capitalize(this.getModule(module))+'/'+id;
  }

  openOnZoho(id: string, module: string) {
    window.open(this.uriOnDesk(id, module));
  }

  copy(id: string, module: string) {
    navigator.clipboard.writeText(this.uriOnDesk(id, module))
      .then(() => {
        this.messageService.showMessage('Ticket URL has been copied', 'success', false);
      })
      .catch((error) => {
          console.error("Error al copiar el texto: ", error);
      });
  }

  openDetails(record: any, module: string): void {
    this.showDetails = true;
    this.contactDetails(module, record);
  }

  contactDetails(module: string, record: any) {
		this.container.clear();
		const contactDetailsComponent = this.container.createComponent(ContactDetailsComponent);
		contactDetailsComponent.instance.record = record;
    contactDetailsComponent.instance.complete = true;
    if (this.action === 'SEARCH-BY-TERM' || this.action === 'SEARCH-BY-PHONE') {
      contactDetailsComponent.instance.complete = false;
    }
		contactDetailsComponent.instance.module = module;
		const contactDetailsEvent$ = contactDetailsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(contactDetailsEvent$);

      if (values.action === 'back') {
				this.showDetails = false;
        this.container.clear();
			}

      if (values.action === 'error') {
        this.emitError(values.values);
      }

      if (values.action === 'update-list') {
				this.getRecords(true);
			}

		});
	}

  seeMore(): void {
    this.page = this.page + 1;
    this.getRecords(false);
  }

  reload(): void {
    this.records = [];
    this.page = 1;
    this.getRecords(true);
  }

  getLocalRecords(): void {
    this.loading = true;
    this.records.push(this.recordDetails);
    this.loading = false;
    if (this.records.length === 1) {
      this.openDetails(this.records[0],this.records[0]._module);
    }
  }

}
