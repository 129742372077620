import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoDeskBackendService } from '../../../data-access/zoho-desk-backend.service';

@Component({
  selector: 'app-ticket-form',
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss']
})
export class TicketDeskFormComponent implements OnDestroy, OnInit {

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private subscriptions = new Subscription();
  public contact?:  any;
  record: any;
  sending = false;
  currentDepartmentId?: string;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    setTimeout(() => {
      this.record = this.contact;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit(data: emitData): void {
    this.sending = true;
    const createRecord$ = this._ZohoDeskBackendService
    .createTickets(data.values)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          this.emitAction.emit({
              action: 'created-ticket',
              values: resp.body,
              module: ''
            });
        }
        this.sending = false;
        this.subscriptions.add(createRecord$);
    }, (error: HttpErrorResponse) => {
      this.sending = false;
      this.emitAction.emit({
        action: 'error',
        values: error,
        module: ''
      });
    });
  }

  back() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}

}
