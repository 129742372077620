import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { MessageService } from '../../utils/messages.service';
import { InitZohoCrmComponent } from '../ui/init-zoho-crm/init-zoho-crm.component';
import { RecordFormComponent } from '../ui/record-form/record-form.component';
import { Organization } from '../../models/organization-response.model';
import { IntegrationConfigResponse } from '../../models/integration-config-response.model';
import { IntegrationConfigComponent } from '../ui/integration-config/integration-config.component';
import { LayoutMetadata } from '../../models/layout-metadata.model';
import { RecordsListComponent } from '../ui/records/records-list.component';
import { ResendConversationEventService } from '../../service/resend-conversation-event.service';
import { ZohoCrmBackendService } from '../data-access/zoho-crm-backend.service';
import { LoadingComponent } from 'src/app/shared/ui/loading/loading.component';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-zoho-crm',
	templateUrl: './zoho-crm.component.html',
	styleUrls: ['./zoho-crm.component.scss']
})
export class ZohoCRMComponent implements OnInit, OnDestroy {
	serviceInstance: IServiceInstance;

	@ViewChild('childZohoCRMComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

  @Output() emitAction: EventEmitter<any> = new EventEmitter<any>();

	@Output() closeZCRM: EventEmitter<any> = new EventEmitter<any>();

	private _ResendConversationEventService = inject(ResendConversationEventService);
	private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private confirmationService = inject(ConfirmationService);
	public translate = inject(TranslateService);

	private fb = inject(FormBuilder);
	private messageService = inject(MessageService);
	loadingActive = false;
	showNavBar = false;
	serviceInstanciaId: string;
	public numberPhone = '';
	public fullName = '';
	searching = false;
	private subscriptions = new Subscription();

	searchForm: FormGroup;

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
		this.serviceInstanciaId = this.serviceInstance.id;
		this.checkIfShowNavBar();
		this.subscribeConversationEvent();
		setTimeout(() => {
      if (localStorage.getItem('ZohoCrmOrgDomainName') == null) {
        this.registerIntegration()
      } else {
        this.checkChatSelected();
      }
    });
		this.createSearchForm();
	}

  checkChatSelected(): any {
    const chat: any = localStorage.getItem('selectedChatConversation');
    if (!chat) {
      this.initZohoCRM();
      return;
    }
    if (chat) {
      const queryChatActive = document.querySelectorAll('.chat-user-list li.active');
      if (queryChatActive.length > 0) {
        this.numberPhone = JSON.parse(chat).clientPhone;
        this.fullName = JSON.parse(chat).customerContact;
        this.container.clear();
        this.recordsList(
          'SEARCH-BY-PHONE',
          'Integration.Zoho.Search record',
          '',
          this.numberPhone,
          null
        );
      } else {
        this.initZohoCRM();
        return;
      }
    }
  }

	subscribeConversationEvent() {
		this.subscriptions.add(
        this._ResendConversationEventService.getEvent().subscribe((data) => {
        if (localStorage.getItem('activeZoho') === 'crm') {
          if (!this.checkIfStartConfiguration()) {
            this.numberPhone = data.clientPhone;
            this.fullName = data.customerContact;
            const formlyFormActive = document.querySelectorAll('.formly-form');
            if (formlyFormActive.length > 0) {
              this.exitFormlyConfirmDialog();
            } else {
              this.container.clear();
              this.recordsList(
                'SEARCH-BY-PHONE',
                'Integration.Zoho.Search record',
                '',
                this.numberPhone,
                null
              );
            }
          } else {
            this.startIntegrationConfig();
          }
        }
      })
    )
	}

  recordsList(action: string, title: string, module: string, term: string, record: any): void {
    this.resetForm();
		this.container.clear();
		const recordsListComponent = this.container.createComponent(RecordsListComponent);
    recordsListComponent.instance.action = action;
    recordsListComponent.instance.module = module;
    recordsListComponent.instance.term = term;
    recordsListComponent.instance.title = title;
    recordsListComponent.instance.recordDetails = record;
		const recordsListEvent$ = recordsListComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordsListEvent$);
			if (values.action === 'cancel') {
				this.initZohoCRM();
			}
      if (values.action === 'create-contact') {
				this.initZohoCRM();
			}
      if (values.action === 'create-contact') {
        this.formRecord('CONTACTS')
			}
      if (values.action === 'create-lead') {
				this.formRecord('LEADS')
			}
      if (values.action === 'error') {
				this.errorHandler(values.values);
			}
    });
	}

  openLeads(): void {
    this.recordsList(
      'OPEN-LEADS',
      'Integration.Zoho.Leads',
      'LEADS',
      '',
      null
      );
  }

  openContacts(): void {
    this.recordsList(
      'OPEN-CONTACTS',
      'Integration.Zoho.Contacts',
      'CONTACTS',
      '',
      null
      );
  }

  initZohoCRM(): void {
		this.container.clear();
		const initZohoCRMComponent = this.container.createComponent(InitZohoCrmComponent);
		const initZohoCRMEvent$ = initZohoCRMComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(initZohoCRMEvent$);
			if (values.action === 'show-leads') {
				this.openLeads();
			}
      if (values.action === 'show-contacts') {
				this.openContacts();
			}
      if (values.action === 'create-contact') {
        this.formRecord('CONTACTS')
			}
      if (values.action === 'create-lead') {
				this.formRecord('LEADS')
			}
		});
	}

	formRecord(module: string): void {
		this.container.clear();
		const recordFormComponent = this.container.createComponent(RecordFormComponent);
    recordFormComponent.instance.record = {
      First_Name: this.fullName,
      Mobile: this.numberPhone,
      Phone: this.numberPhone,
      Secondary_Phone: this.numberPhone,
      Full_Name: this.fullName,
    };
		recordFormComponent.instance.module = module;
		const recordFormEvent$ = recordFormComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordFormEvent$);

      if (values.action === 'create-record') {
				this.messageService.showMessage('The record has been created.', 'success', true);
      }

      if (values.action === 'update-record') {
				this.messageService.showMessage('The record has been updated.', 'success', true);
      }

			if (values.action === 'create-record' || values.action === 'update-record') {
        this.recordsList(
          'OPEN-DETAILS',
          'Integration.Zoho.New record',
          '',
          '',
          values.values
        );
      }

			if (values.action === 'error') {
				this.errorHandler(values.values);
			}
			if (values.action === 'cancel') {
        this.initZohoCRM();
			}
		});
	}

	loadingZohoCRM(): void {
		this.container.clear();
		this.loadingActive = true;
		this.container.createComponent(LoadingComponent);
	}

	registerIntegration(): void {
		this.loadingZohoCRM();
		const registerIntegration$ = this._ZohoCrmBackendService.registerIntegration().subscribe(
			(resp: HttpResponse<Organization>) => {
				this.loadingActive = false;
				if (resp.status == 200) {
          this.startIntegrationConfig();
					localStorage.setItem('ZohoCrmOrgDomainName', resp.body.domainName);
				}
				this.subscriptions.add(registerIntegration$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

	startIntegrationConfigComponent(integrationConfigResponse: IntegrationConfigResponse): void {
		this.container.clear();
		const onIntegrationConfigComponent = this.container.createComponent(IntegrationConfigComponent);
		onIntegrationConfigComponent.instance.integrationConfigResponse = integrationConfigResponse;
    onIntegrationConfigComponent.instance.zohoType = 'crm';
		const onLoginEvent$ = onIntegrationConfigComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onLoginEvent$);
			if (values.action === 'save-init-config') {
				const registerIntegration$ = this._ZohoCrmBackendService
					.registerIntegrationConfig(values.values)
					.subscribe(
						(res: HttpResponse<LayoutMetadata>) => {
							const layoutMetaData: LayoutMetadata = res.body;
							localStorage.setItem('leads-metadata-crm', JSON.stringify(layoutMetaData.leadsSections));
							localStorage.setItem('contacts-metadata-crm', JSON.stringify(layoutMetaData.contactsSections));
							localStorage.setItem('deals-metadata-crm', JSON.stringify(layoutMetaData.dealsSections));
							localStorage.setItem('tasks-metadata-crm', JSON.stringify(layoutMetaData.tasksSections));
							localStorage.setItem('events-metadata-crm', JSON.stringify(layoutMetaData.eventsSections));
							localStorage.setItem('calls-metadata-crm', JSON.stringify(layoutMetaData.callsSections));
							this.messageService.showMessage('The settings have been saved.', 'success', true);
							this.container.clear();
							this.initZohoCRM();
							this.subscriptions.add(registerIntegration$);
						},
						(error: HttpErrorResponse) => this.errorHandler(error)
					);
			}
      if (values.action === 'logout') {
        this.logout();
      }
      if (values.action === 'init') {
        this.container.clear();
        this.initZohoCRM();
      }
		});
	}

	startIntegrationConfig() {
   this.loadingZohoCRM();
      const calls: any[] = [];
      calls.push(this._ZohoCrmBackendService.getLayoutsConfig());
      const forkJoin$ = forkJoin(calls).subscribe(
        (data: any[]) => {
          this.loadingActive = false;
          const integrationConfigResponse: IntegrationConfigResponse = data[0].body;
          if (integrationConfigResponse && integrationConfigResponse.layouts) {
            localStorage.setItem('zohoCrmLayouts', JSON.stringify(integrationConfigResponse.layouts));
          }
          this.startIntegrationConfigComponent(integrationConfigResponse);
          this.subscriptions.add(forkJoin$);
        },
        (error: HttpErrorResponse) => this.errorHandler(error)
      );
	}

	errorHandler(error: HttpErrorResponse): void {
		this.searching = false;
		this.searchForm.controls.searchInput.enable();
		if (error && error.error && error.error.message && error.error.code) {
			const errorMessage: string = error.error.message;
			const errorCode: string = error.error.code;
			if (
        errorCode.startsWith('error.zohocrm.auth') ||
        errorCode.startsWith('INVALID TOKEN ERROR') ||
        errorCode.startsWith('unauthorized.access') ||
        errorCode.startsWith('401') ||
        errorCode.startsWith('403') ||
        errorCode.startsWith('500') ||
        errorCode.startsWith('error.zohocrm.agent.not.contain.the.integration')
      ) {
				this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
			} else {
        this.messageService.showMessage(errorMessage, 'error', false);
        if (this.loadingActive) {
          this.initZohoCRM();
        }
      }
		} else {
			this.messageService.showMessage('An error has occurred, try again', 'error', false);
			this.initZohoCRM();
		}
	}

	checkIfShowNavBar(): void {
		this.showNavBar = localStorage.getItem('zohoCrmCredencials') != null;
	}

  checkIfStartConfiguration(): boolean {
		return localStorage.getItem('leads-metadata-crm') == null;
	}

	logout(): void {
		this.emitAction.emit({
      action: 'logout',
      values: '',
      module: ''
    });
	}

	createSearchForm(): void {
		this.searchForm = this.fb.group({});
		this.searchForm.addControl('searchInput', this.fb.control('', []));
	}

	search(): void {
    if (this.searchForm.value['searchInput'].length > 2) {
      this.recordsList(
        'SEARCH-BY-TERM',
        'Integration.Zoho.Search record',
        '',
        this.searchForm.value['searchInput'],
        null
        );
    } else {
      this.messageService.showMessage('Enter at least 3 letters', 'warning', true);
    }
	}

  back(): void {
    this.emitAction.emit({
      action: 'close',
      values: '',
      module: ''
    });
  }

  resetForm(): void {
    this.searchForm.patchValue({
      searchInput: ''
    });
  }

  exitFormlyConfirmDialog() {
    this.confirmationService.confirm({
        message: this.translate.instant('Integration.Zoho.Are you sure you want to leave the form?'),
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.container.clear();
          this.recordsList(
            'SEARCH-BY-PHONE',
            'Integration.Zoho.Search record',
            '',
            this.numberPhone,
            null
          );
        },
        key: 'exitFormlyFormConfirm'
    });
  }

}
