import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs/operators';
import { LoginService } from './shared/data-access/login.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private eventService: PublicEventsService,
		private loginService: LoginService,
		private translate: TranslateService,
		private router: Router
	) {
		this.initializeTranslation();
	}

	ngOnInit() {
		this.loginService.handleLogin();
		this.registerForAuthEvents();
	}

	private initializeTranslation(): void {
		const storedLanguagePreference = localStorage.getItem('storedLanguagePreference');
		const defaultLanguage = storedLanguagePreference || 'en';
		this.translate.setDefaultLang(defaultLanguage);
		this.translate.use(defaultLanguage);
	}

	private registerForAuthEvents(): void {
		this.eventService
			.registerForEvents()
			.pipe(
				filter((notification) =>
					[EventTypes.CheckingAuthFinishedWithError, EventTypes.SilentRenewFailed].includes(notification.type)
				)
			)
			.subscribe(() => {
				this.router.navigate(['/unauthorized']);
			});
	}
}
