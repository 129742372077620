import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize, map } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CreateAccount, CreateAccountDTO, CreateAccountData } from '../interface/create-account.interface';
import { Account, SearchAccountDTO } from '../interface/search-account.interface';
import { AuthenticationTTCService } from './authentication-ttc.service';

@Injectable({
	providedIn: 'root'
})
export class AccountTTCService {
	private baseUrl = environment.URL_API_TIME_TO_CHANGE;

	constructor(private http: HttpClient, private _AuthenticationTTCService: AuthenticationTTCService) {}

	searchAccounts(data: string | undefined): Observable<Account[]> {
		const URL = this.baseUrl + '/api/v1/searchAccount';

		return this.http.post<SearchAccountDTO>(URL, { query: data }).pipe(
			map((response) => {
				return response.data.data;
			})
		);
	}

	createAccount(data: CreateAccount): Observable<CreateAccountData> {
		const URL = this.baseUrl + '/api/v1/account';

		return this.http.post<CreateAccountDTO>(URL, data).pipe(
			map((response) => {
				return response.data;
			}),
		);
	}

  getAccounts(accountType: string): Observable<any> {
		const URL = this.baseUrl + '/api/v1/searchAccountWithFilter?accountType='+accountType+'&accountName&accountIdentification';
		return this.http.get<any>(URL).pipe(
			map((response) => {
				return response.data.data;
			})
		);
	}

}
