import { Component, inject, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MessagingHubService } from '../../../chat/data-access/services/messaging-hub.service';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {
	private oidcSecurityService = inject(OidcSecurityService);
	private messagingHubService = inject(MessagingHubService);

	public ngOnInit(): void {
		this.messagingHubService.stopConnection();
		this.oidcSecurityService.logoff().subscribe();
	}
}
